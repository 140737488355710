@import '../../../../scss/theme-bootstrap';

.product-gifting {
  position: relative;
  font-size: 18px;
  .gifting-form {
    position: absolute;
    top: 2em;
    left: -3.5em;
    padding: 1.75em 1.5em;
    background-color: $color-white;
    border: 1px solid $color-dark-gray;
    z-index: 10;
    width: 15em;
    text-align: left;
    color: $color-black;
    &.in-quickshop {
      left: -7em;
      @include breakpoint($small-down) {
        left: -4em;
        top: 3em;
      }
    }
    &__arrow {
      @include bordered-triangle(18px, $color-white, up, $color-black);
      position: absolute;
      top: -1em;
      left: 7em;
    }
    &__top {
      text-align: center;
      &--hdr {
        @include h9;
        font-size: 40px;
        display: inline;
        text-transform: uppercase;
      }
      &--icon {
        margin-top: -0.75em;
        font-size: 1.5em;
      }
    }
    &__close {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px;
    }
    &__form {
      .form-choose {
        margin-bottom: 0.25em;
        &__hdr {
          @include t6;
          margin: 0 0 0.5em 0;
          text-align: center;
          color: $color-dark-gray;
        }
        .gift-name {
          li {
            padding: 0.5em;
            cursor: pointer;
            &.active {
              background-color: $color-light-gray;
            }
          }
        }
      }
      &--input {
        width: 100%;
        margin: 0.5em 0;
        padding-left: 0.5em;
        padding-right: 0.5em;
      }
      &--btn {
        width: 100%;
      }
      &--error {
        color: $color-red;
        font-size: 14px;
        li {
          margin-bottom: 1em;
        }
      }
    }
  }
}
